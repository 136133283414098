import fire from "./fire";
import { isValidEmail, isValidParish } from "./validation";
import { get4DigitId } from "../utils/utils";
import firebase from "firebase/app";
import { getTagByPosition, isPriorityAccount } from "../utils/positions";

export async function isUserLoggedIn() {
  const currentUser = await fire.auth().currentUser;
  if (currentUser) {
    return true;
  }
  return false;
}

export async function userLogout() {
  return await fire.auth().signOut();
}

export async function getCurrentUser() {
  return await fire.auth().currentUser;
}

export async function getCurrentUserData(uid) {
  if (!uid) {
    return null;
  }

  const db = fire.firestore();
  const user = await db.doc(`users/${uid}`).get();

  if (user && user.exists) {
    return user.data();
  } else {
    return null;
  }
}

export async function getUserCVData(uid) {
  if (!uid) {
    return null;
  }

  const db = fire.firestore();
  const user = await db.doc(`users/${uid}`).get();

  if (user && user.exists) {
    //TODO: remove other data
    return user.data();
  } else {
    return null;
  }
}

export async function getParishioners(parish) {
  if (!isValidParish(parish)) {
    return null;
  }

  const db = fire.firestore();

  const parishioners = await db
    .collection("users")
    .where("parish", "==", parish)
    //.orderBy("fullname", "asc")
    .get();
  var list = [];
  parishioners.forEach((doc) => {
    list.push(doc.data());
  });
  return list;
}

export async function getXUsers(count = 500, when = null, extramonth = 0) {
  const db = fire.firestore();
  let users = [];
  if (!when) {
    users = await db
      .collection("users")
      .limit(count)
      .orderBy("created", "desc")
      .get();
  } else {
    //get user created within a specified date from 12:00:01 midnight to 11:59:59
    // console.log(when);
    const whenformattedstart = new Date(when);
    const whenformattedend = new Date(when);
    whenformattedstart.setHours(0, 0, 1);
    whenformattedend.setHours(23, 59, 59);
    whenformattedend.setMonth(whenformattedend.getMonth() + extramonth); // Add exactly 1 month
    // console.log(whenformattedend);
    // console.log(whenformattedstart);
    users = await db
      .collection("users")
      .where("created", ">=", new Date(whenformattedstart).getTime())
      .where("created", "<=", new Date(whenformattedend).getTime()) // whenformattedend)
      .orderBy("created", "desc")
      .get();
  }
  // console.log(users.docs.length);
  const list = [];
  for (let i = 0; i < users.docs.length; i++) {
    const entry = users.docs[i].data();
    if (!entry?.test && entry?.fullname !== "") {
      const created = new Date(entry.created);
      const createdformatted = created.toLocaleDateString("en-GB", {
        day: "numeric",
        month: "short",
        year: "numeric",
      });
      entry.createdformatted = createdformatted;
      list.push({ ...entry });
    }
  }
  //sort based on created
  list.sort((a, b) => {
    return b.created - a.created;
  });
  // if (list.length > 0) {
  //   const str = JSON.stringify(list);
  //   console.log(str.length / 1024);
  // }
  // console.log(list);
  return list;
}

export async function getAllUsers() {
  const db = fire.firestore();

  const users = await db.collection("users").get();
  const list = [];
  for (let i = 0; i < users.docs.length; i++) {
    const entry = users.docs[i].data();
    if (!entry?.test) {
      const created = new Date(entry.created);
      const createdformatted = created.toLocaleDateString("en-GB", {
        day: "numeric",
        month: "short",
        year: "numeric",
      });
      entry.createdformatted = createdformatted;
      list.push({ ...entry });
    }
  }
  //sort based on created
  list.sort((a, b) => {
    return b.created - a.created;
  });
  const str = JSON.stringify(list);
  console.log(str.length / 1024);
  return list;
}

export async function createWithEmailAndPassword(email, password) {
  // await fire.auth().createUserWithEmailAndPassword(email, password);
  let errors;

  try {
    const result = await fire
      .auth()
      .createUserWithEmailAndPassword(email, password);
    // throw "Forced error"; //{ code: "/forced-error", email: "Forced Error!" };
    //IS IT POSSIBLE THAT result.user IS NULL?
    errors = { code: "success", user: result.user };
  } catch (ex) {
    if (ex.code === "auth/email-already-in-use") {
      errors = {
        email: `${ex.message} Please try to login instead.`,
        code: ex.code,
        message: ex.message,
      };
    } else if (ex.code === "auth/invalid-email") {
      errors = { email: ex.message, code: ex.code, message: ex.message };
    } else if (ex.code === "auth/operation-not-allowed") {
      errors = { email: ex.message, code: ex.code, message: ex.message };
    } else if (ex.code === "auth/weak-password") {
      errors = {
        password: "Password is too weak",
        code: ex.code,
        message: ex.message,
      };
    } else {
      errors = {
        email: "Unknown error. Please try again later.",
        intercept: true, //let's try to catch the error what's really going on????
        code: ex.code,
        message: ex.message,
      };
    }
  }
  return errors;
}

export function getUserWithSeaBasedExperience(users) {
  const withSeabased = [];
  for (let i = 0; i < users.length; i++) {
    const user = users[i];
    if (
      user?.exp?.length > 0 &&
      (!user.hasOwnProperty("excludefromemail") ||
        user?.excludefromemail === false)
    ) {
      withSeabased.push(user);
    }
  }
  return withSeabased;
}

export function getUserWithSeaBasedExperienceCount(users) {
  let count = 0;
  for (let i = 0; i < users.length; i++) {
    const user = users[i];
    if (
      user?.exp?.length > 0 &&
      (!user.hasOwnProperty("excludefromemail") ||
        user?.excludefromemail === false)
    ) {
      count++;
    }
  }
  return count;
}

export async function getUserByEmailInAuth(email) {
  const emailtrim = email?.toLowerCase()?.trim();
  if (!isValidEmail(emailtrim)) {
    return null;
  }

  const func = fire.functions("asia-east1").httpsCallable("getUserDataByEmail");
  const out = await func({ email: emailtrim });

  return out;
}

export async function getUserByUid(uid) {
  var list = [];
  if (uid === null) {
    return list;
  }
  //call admin cloud function here
  const db = fire.firestore();

  const parishioners = await db
    .collection("users")
    .where("userid", "==", uid)
    .get();

  parishioners.forEach((doc) => {
    list.push(doc.data());
  });
  return list;
}

export async function getUsersBySubid(subid) {
  var list = [];
  if (subid === null) {
    return list;
  }
  //call admin cloud function here
  const db = fire.firestore();

  const parishioners = await db
    .collection("users")
    .where("subid", "==", subid.toUpperCase().trim())
    .orderBy("fullname", "asc")
    .get();

  parishioners.forEach((doc) => {
    list.push(doc.data());
  });
  return list;
}

export async function getUsersByMobile(mobile) {
  var list = [];
  if (mobile === null) {
    return list;
  }
  //call admin cloud function here
  const db = fire.firestore();

  const parishioners = await db
    .collection("users")
    .where("mobile", "==", mobile.trim())
    .orderBy("fullname", "asc")
    .get();

  parishioners.forEach((doc) => {
    list.push(doc.data());
  });
  return list;
}

export async function getUserByEmail(email) {
  let emailtrim = email.toLowerCase().trim();
  if (!isValidEmail(emailtrim)) {
    return null;
  }
  //call admin cloud function here
  let foundemail = emailtrim;
  const db = fire.firestore();

  const parishioners = await db
    .collection("users")
    .where("email", "==", foundemail)
    .get();
  var list = [];
  parishioners.forEach((doc) => {
    list.push(doc.data());
  });
  return list;
}

export async function getUserMassBookings(uid) {
  if (!uid) {
    return null;
  }

  const db = fire.firestore();

  const bookings = await db
    .collection("parishionerbookings")
    .where("parishionerid", "==", uid)
    .orderBy("massdate", "desc")
    .get();

  var list = [];
  bookings.forEach((doc) => {
    list.push(doc.data());
  });
  return list;
}

export async function updateUserEmail(userId, email) {
  const db = fire.firestore();

  //1. update users
  var userRef = db.collection(`users`).doc(userId);
  try {
    return await db.runTransaction(async (transaction) => {
      const doc = await transaction.get(userRef);
      if (!doc.exists) {
        console.log("User not found.");
        // throw "Document does not exist!";
        return { status: 1, message: "User not found." };
      }

      const created = new Date().getTime();

      transaction.update(userRef, {
        lastupdate: created,
        email,
      });
      //success
      console.log("Update successful");
      return { status: 0, message: "Update successful." };
    });
  } catch (error) {
    console.log("Error updating account.");

    return { status: 3, message: "Error updating account." };
  }
}

export async function updateAccountIdentification(userId, id, secureid) {
  let subId = get4DigitId(id);

  const db = fire.firestore();
  //1. update parishionerbookings
  const parishionerBookingRef = await db.collection("parishionerbookings");
  let result = await parishionerBookingRef
    .where("parishionerid", "==", userId)
    .get();
  //update identification and subid
  result.forEach(async (doc) => {
    console.log("Doc: ", doc);
    await parishionerBookingRef
      .doc(doc.id)
      .update({ identification: secureid, subid: subId });
  });
  //3. update users
  var userRef = db.collection(`users`).doc(userId);
  try {
    return await db.runTransaction(async (transaction) => {
      const doc = await transaction.get(userRef);
      if (!doc.exists) {
        console.log("User not found.");
        // throw "Document does not exist!";
        return { status: 1, message: "User not found." };
      }

      const created = new Date().getTime();

      transaction.update(userRef, {
        lastupdate: created,
        identification: secureid,
        subid: subId,
      });
      //success
      console.log("Update successful");
      return { status: 0, message: "Update successful." };
    });
  } catch (error) {
    console.log("Error updating account.");

    return { status: 3, message: "Error updating account." };
  }
}

export async function updateParishDetail(userId, data) {
  const db = fire.firestore();
  console.log("Data: ", data);

  //1. update users
  var userRef = db.collection(`users`).doc(userId);
  try {
    return await db.runTransaction(async (transaction) => {
      const doc = await transaction.get(userRef);
      if (!doc.exists) {
        console.log("User not found.");
        // throw "Document does not exist!";
        return { status: 1, message: "User not found." };
      }

      const created = new Date().getTime();
      const { parish } = data;

      transaction.update(userRef, {
        lastupdate: created,
        parish,
      });
      //success
      console.log("Update successful");
      return { status: 0, message: "Update successful." };
    });
  } catch (error) {
    console.log("Error updating account.");

    return { status: 3, message: "Error updating account." };
  }
}

export async function updateBasicAccountDetails(userId, data) {
  const db = fire.firestore();
  console.log("Data: ", data);
  //1. update feedback
  const feedbackRef = await db.collection("feedback");
  let result = await feedbackRef.where("authorid", "==", userId).get();
  result.forEach(async (doc) => {
    console.log("Doc: ", doc);
    await feedbackRef.doc(doc.id).update({ author: data.fullname });
  });
  //2. update parishionerbookings
  const parishionerBookingRef = await db.collection("parishionerbookings");
  result = await parishionerBookingRef
    .where("parishionerid", "==", userId)
    .get();
  result.forEach(async (doc) => {
    console.log("Doc: ", doc);
    await parishionerBookingRef.doc(doc.id).update({ fullname: data.fullname });
  });
  //3. update users
  var userRef = db.collection(`users`).doc(userId);
  try {
    return await db.runTransaction(async (transaction) => {
      const doc = await transaction.get(userRef);
      if (!doc.exists) {
        console.log("User not found.");
        // throw "Document does not exist!";
        return { status: 1, message: "User not found." };
      }

      const created = new Date().getTime();
      const { fullname, dob, mobile } = data;

      transaction.update(userRef, {
        lastupdate: created,
        fullname: fullname.trim(),
        dob: dob.trim(),
        mobile: mobile.trim(),
      });
      //success
      console.log("Update successful");
      return { status: 0, message: "Update successful." };
    });
  } catch (error) {
    console.log("Error updating account.");

    return { status: 3, message: "Error updating account." };
  }
}

export async function updateBasicUserDetailsByTransaction(userId, data) {
  const db = fire.firestore();
  var userRef = db.collection(`users`).doc(userId);
  try {
    return await db.runTransaction(async (transaction) => {
      const doc = await transaction.get(userRef);
      if (!doc.exists) {
        console.log("User not found.");
        // throw "Document does not exist!";
        return { status: 1, message: "User not found." };
      }
      // const fbdoc = await transaction
      //   .where("authorid", "==", userId)
      //   .get(feedbackRef);
      // if (!fbdoc.exists) {
      //   console.log("authorid not found.");
      //   // throw "Document does not exist!";
      //   // return { status: 1, message: "User not found." };
      // }

      const created = new Date().getTime();
      const { fullname, dob, mobile } = data;
      //TODO: 1. update parishioner collection
      /*
      parishioner.fullname = fullname
      parishioner.parish = parish
      parishioner.parishcode
      */
      //TODO: 2. update feedback collection
      /*
     feedback.author = fullname
     */
      transaction.update(userRef, {
        lastupdate: created,
        fullname,
        dob,
        mobile,
      });
      //success
      console.log("Update successful");
      return { status: 0, message: "Update successful." };
    });
  } catch (error) {
    console.log("Error updating account.");

    return { status: 3, message: "Error updating account." };
  }
}

export async function updateCurrentUserPhotos(uid, smallPic, bigPic) {
  if (!uid) {
    return null;
  }

  const db = fire.firestore();
  const user = await db.doc(`users/${uid}`).get();

  if (user && user.exists) {
    await db.doc(`users/${uid}`).update({ smallpic: smallPic, bigpic: bigPic });
    return getCurrentUser();
  } else {
    return null;
  }
}
export async function updateUserProductList(uid, list) {
  if (!uid) {
    return null;
  }

  const db = fire.firestore();
  const user = await db.doc(`users/${uid}`).get();

  if (user && user.exists) {
    await db.doc(`users/${uid}`).update({ products: list });
    return getCurrentUser();
  } else {
    return null;
  }
}

export async function markUserEmailContactable(
  uid,
  isContactable = true,
  setTimestamp = false
) {
  if (!uid) {
    return null;
  }

  const db = fire.firestore();
  const user = await db.doc(`users/${uid}`).get();

  if (user && user.exists) {
    let obj;
    if (!setTimestamp) {
      obj = { emailContactable: isContactable };
    } else {
      //need to set timestamp
      obj = {
        emailContactable: isContactable,
        lastverification: new Date().getTime(),
      };
    }
    await db.doc(`users/${uid}`).update(obj);
    return obj;
  } else {
    return null;
  }
}

export async function markUserHasVerified(
  uid,
  hasVerified = false,
  setTimestamp = false
) {
  if (!uid) {
    return null;
  }

  const db = fire.firestore();
  const user = await db.doc(`users/${uid}`).get();

  if (user && user.exists) {
    let obj;
    if (!setTimestamp) {
      obj = { hasVerified: hasVerified };
    } else {
      //need to set timestamp
      obj = {
        hasVerified: hasVerified,
        lastverification: new Date().getTime(),
      };
    }
    await db.doc(`users/${uid}`).update(obj);
    return getCurrentUser();
  } else {
    return null;
  }
}

export async function markUserHasVerifiedContactable(
  uid,
  hasVerified = false,
  setTimestamp = false
) {
  if (!uid) {
    return null;
  }

  const db = fire.firestore();
  const user = await db.doc(`users/${uid}`).get();

  if (user && user.exists) {
    let obj;
    if (!setTimestamp) {
      obj = { hasVerified: hasVerified, emailContactable: hasVerified };
    } else {
      //need to set timestamp
      obj = {
        hasVerified: hasVerified,
        emailContactable: hasVerified,
        lastverification: new Date().getTime(),
      };
    }
    await db.doc(`users/${uid}`).update(obj);
    return obj;
  } else {
    return null;
  }
}

export async function setDetailsVisibility(uid, isVisible = true) {
  if (!uid) {
    return null;
  }

  const db = fire.firestore();
  const user = await db.doc(`users/${uid}`).get();

  if (user && user.exists) {
    await db
      .doc(`users/${uid}`)
      .update({ visible: isVisible, lastupdated: new Date().getTime() });
    return getCurrentUser();
  } else {
    return null;
  }
}

export async function setHasRequestEmailVerification(uid, value = true) {
  if (!uid) {
    return null;
  }

  const db = fire.firestore();
  const user = await db.doc(`users/${uid}`).get();

  if (user && user.exists) {
    await db.doc(`users/${uid}`).update({
      hasrequestemailverify: value,
      lastupdated: new Date().getTime(),
    });
    return getCurrentUser();
  } else {
    return null;
  }
}

export async function setSeenUploadCV(uid, value) {
  if (!uid) {
    return null;
  }

  const db = fire.firestore();
  const user = await db.doc(`users/${uid}`).get();

  if (user && user.exists) {
    await db
      .doc(`users/${uid}`)
      .update({ seenuploadcv: value, lastupdated: new Date().getTime() });
    return getCurrentUser();
  } else {
    return null;
  }
}

export async function updateLastUpdated(uid, value = 0) {
  if (!uid) {
    return false;
  }
  // console.log(uid);

  const db = fire.firestore();

  try {
    //calculate last updated if more than 6 hours
    const sixHours = 6 * 60 * 60 * 1000;
    const lastUpdated = value;
    const diff = new Date().getTime() - lastUpdated;
    if (diff > sixHours) {
      //update
      // console.log("Updating");
      await db
        .doc(`users/${uid}`)
        .update({ lastupdated: new Date().getTime() });
      return true;
    }
    return false;
  } catch (e) {
    console.log("Error updating last updated: ", e);
    return false;
  }
}

export async function setDesignBackground(uid, background) {
  if (!uid) {
    return null;
  }

  const db = fire.firestore();
  const user = await db.doc(`users/${uid}`).get();

  if (user && user.exists) {
    await db
      .doc(`users/${uid}`)
      .update({ background: background, lastupdated: new Date().getTime() });
    return getCurrentUser();
  } else {
    return null;
  }
}

// Function to add tags to a user
export async function addTagsToUser(data, tags) {
  const userId = data?.userid;
  // Reference to the user document
  const db = fire.firestore();
  const userRef = db.collection("users").doc(userId);

  try {
    // Update the user document with the new tags
    await userRef.update({
      autopos: getTagByPosition(data),
      autoprio: isPriorityAccount(data),
      tags: firebase.firestore.FieldValue.arrayUnion(...tags),
    });
    console.log("Tags added successfully!");
  } catch (error) {
    console.error("Error adding tags: ", error);
  }
}

// Function to remove a tag from a user
export async function removeTagFromUser(userId, tag) {
  // Reference to the user document
  const db = fire.firestore();
  const userRef = db.collection("users").doc(userId);

  try {
    // Update the user document to remove the tag
    await userRef.update({
      tags: firebase.firestore.FieldValue.arrayRemove(tag),
    });
    console.log(`Tag ${tag} removed successfully!`);
  } catch (error) {
    console.error("Error removing tag: ", error);
  }
}

export function getUserLatestRank(data) {
  const defaultRank = "None";
  if (data?.hasOwnProperty("exp") && data?.exp?.length > 0) {
    return data.exp[0]?.title || defaultRank;
  } else {
    return defaultRank;
  }
}

export function getUserLatestVesselType(data) {
  const defaultRank = "None";
  if (data?.hasOwnProperty("exp") && data?.exp?.length > 0) {
    return data.exp[0]?.type || defaultRank;
  } else {
    return defaultRank;
  }
}

export function getUserBio(data) {
  const defaultBio = "None";
  if (data?.hasOwnProperty("bio")) {
    let bio = data?.bio || defaultBio;
    bio = bio?.replace(/\//g, "/ ").replace(/\-/g, "- ");
    return bio;
  } else {
    return defaultBio;
  }
}

export function getUserTags(data) {
  const defaultRank = [];
  if (data?.hasOwnProperty("tags") && data?.tags?.length > 0) {
    return data.tags;
  } else {
    return defaultRank;
  }
}

export async function tryUpdateUserData(currentData, newData) {
  //check if currentData field is empty, then use new Data????
  /*
  edu,
  train,
  travel,
  exp,
  license,
  reference,
  vac
  */
  //just override
  const uid = currentData?.uid;
  const db = fire.firestore();
  const user = await db.doc(`users/${uid}`).get();
  if (user && user.exists) {
    console.log(`Updating user ${uid} with new data:`, newData);
    await db.doc(`users/${uid}`).update({ ...newData });
    return getCurrentUser();
  } else {
    return null;
  }
}
